<template>
    <div class="passwordset">
        <Top name="密码管理" back email></Top>
        <div class="passwordset_w">
           <van-tabs v-model="active">
                <van-tab title="登录密码"></van-tab>
                <van-tab title="支付密码"></van-tab>
            </van-tabs>
            <div class="password_w" v-show="active == 0">
                <van-form ref="form" validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                        v-model="form.phone"
                        name="pattern"
                        label="手机号"
                        disabled = true
                        oninput = "value=value.replace(/[^\d]/g,'')"
                        placeholder="输入您注册时的手机号码"
                        :rules="[{ pattern, message: '请输入正确的手机号码' }]"
                    />
                    <van-field
                        v-model="form.yzm"
                        name="validator"
                        oninput = "value=value.replace(/[^\d]/g,'')"
                        center
                        label="验证码"
                        autocomplete="new-password"
                        placeholder="输入手机验证码"
                        auto-complete="new-password"
                        :rules="[{ validator: validatorCode, message: '请输入正确的验证码' }]"
                    >
                        <template #button>
                            <GetYzmCode :username="form.phone"></GetYzmCode>
                        </template>
                    </van-field>
                    <van-field
                        v-model="form.oldPassword"
                        name="validator"
                        type="password"
                        label="新密码"
                        autocomplete="new-password"
                        placeholder="输入新密码"
                        auto-complete="new-password"
                        :rules="[{ validator: validatorPassword, message: '请输入新密码' }]"
                    />
                    <van-field
                        v-model="form.newPassword"
                        name="validator"
                        type="password"
                        label="再输一次"
                        autocomplete="new-password"
                        placeholder="再次输入新密码"
                        auto-complete="new-password"
                        :rules="[{ validator: validatorNewPassword, message: '两次密码不一致' }]"
                    />
                    <div class="sumbit_btn">
                        <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" native-type="submit">密码重置</van-button>
                    </div>
                </van-form>
            </div>
            <div class="pay_w" v-show="active == 1">
                <van-form ref="payForm" @submit="onSubmit">
                    <van-field
                        v-model="payForm.phone"
                        label="手机号"
                        disabled = true
                        oninput = "value=value.replace(/[^\d]/g,'')"
                        placeholder="输入手机号码"
                        auto-complete="new-password"
                        :rules="[{ pattern, message: '请输入正确的手机号码' }]"
                        v-if="isSet"
                    />
                    <van-field
                        v-model="payForm.yzm"
                        name="validator"
                        center
                        label="验证码"
                        oninput = "value=value.replace(/[^\d]/g,'')"
                        placeholder="输入手机验证码"
                        auto-complete="new-password"
                        :rules="[{ validator: validatorCode, message: '请输入正确的验证码' }]"
                        v-if="isSet"
                    >
                        <template #button>
                            <GetYzm :username="payForm.phone"></GetYzm>
                        </template>
                    </van-field>
                    <div class="pay_wt">
                        <span>密码设置</span>
                        <van-password-input
                            :value="payForm.oldPassword"
                            gutter="5"
                            :focused="showKeyboard"
                            @focus="showKeyboard = true"
                        />
                        <van-number-keyboard
                            v-model="payForm.oldPassword"
                            :maxlength="6"
                            :show="showKeyboard"
                            @blur="showKeyboard = false"
                        />
                    </div>
                    <div class="pay_wt">
                        <span>再次确认</span>
                        <van-password-input
                            :value="payForm.newPassword"
                            gutter="5"
                            :focused="showKeyboard1"
                            @focus="showKeyboard1 = true"
                        />
                        <van-number-keyboard
                            v-model="payForm.newPassword"
                            :maxlength="6"
                            :show="showKeyboard1"
                            @blur="showKeyboard1 = false, confirmPassword()"
                        />
                    </div>
                    <p class="error" v-show="!isTrue">两次密码不一致</p>
                    <div class="sumbit_btn">
                        <van-button round type="info" size="large" color="linear-gradient(to right, #4a9af7, #0165da)" native-type="submit">密码{{isSet ? '重置' : '设置'}}</van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
 import Top from '@/components/top'
 import GetYzmCode from '@/components/getYzmCode'
 import GetYzm from '@/components/getYzmCode'

export default {
    name: 'Passwordset',
    components: {
        Top,
        GetYzmCode,
        GetYzm
    },
    data() {
        return {
            active: 0,
            form: {
                phone: '',
                yzm: '',
                oldPassword: '',
                newPassword: '',
            },
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            codeText: '获取',
            payForm: {
                phone: '',
                yzm: '',
                oldPassword: '',
                newPassword: ''
            },
            showKeyboard: false,
            showKeyboard1: false,
            isTrue: true,
            isSet: false
        }
    },
    created(){
        //console.log(this.$route.params.active)
        let str = this.$route.params.active
        this.active = str?str:0;
        let username = localStorage.getItem('username');
        this.form.phone = username;
        this.payForm.phone = username;
        //判断支付密码是否设置
        this.isSettig();
    },
    methods: {
        // 校验函数返回 true 表示校验通过，false 表示不通过
        //校验验证码
        validatorCode(val) {
            //console.log(val);
            return true;
        },
        //校验新密码
        validatorPassword(val) {
            return val.trim().length > 0 ? true : false;
        },
        //校验确认密码
        validatorNewPassword(val) {
            return val == this.form.oldPassword ? true : false;
        },
        //提交报错内容
        onFailed(errorInfo) {
            //console.log(errorInfo);
        },
        //提交
        onSubmit() {
            if(this.active==0){
                if(this.form.yzm==''){
                    this.$toast({
                        duration: 1500,
                        message: '请输入验证码'
                    });
                    return false;
                }
                //验证验证码
                this.$api.register.checkCode({
                    username: this.form.phone,
                    code: this.form.yzm
                }).then(res => {
                    if (res.code == 200) {
                        this.$api.login.resetPassword({
                            username: this.form.phone,
                            password: this.form.oldPassword,
                            password2: this.form.newPassword,
                            code: this.form.yzm
                        }).then(res => {
                            if (res.code == 200) {
                                this.$toast({
                                    message: '密码修改成功！',
                                    duration: 1500
                                });
                                localStorage.removeItem('token');
                                localStorage.removeItem('username');
                                this.$router.push('/login');
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                    }else{
                        this.$toast({
                            message: '验证码错误！',
                            duration: 1500
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
            }else{
                // type	是	string	类型：1实名绑定 2支付类型
                // password	是	sting	密码设置
                // again_password	是	sting	再次确认
                // code	否	sting	验证码，忘记密码时传
                if(this.isSet){
                    if(this.payForm.yzm==''){
                        this.$toast({
                            duration: 1500,
                            message: '请输入验证码'
                        });
                        return false;
                    }
                }
                if (this.payForm.oldPassword.trim() && this.payForm.newPassword.trim()) {
                    if (this.payForm.oldPassword == this.payForm.newPassword) {
                        let data = {
                            type:2,
                            password:this.payForm.oldPassword,
                            again_password:this.payForm.newPassword,
                            code:this.payForm.yzm
                        }
                        this.$api.account.securitySettings(data).then((res)=>{
                            //console.log(res)
                            if(res.code==200){
                                this.$toast({
                                    message: '密码修改成功！',
                                    duration: 1500
                                });
                                this.$router.push('/setting');
                            }
                        })
                    }
                } else {
                    this.$toast({
                        message: '请先完善输入框!',
                        duration: 1500
                    });
                }
            }
        },
        //确认密码是否一致
        confirmPassword() {
            if (this.payForm.newPassword != this.payForm.oldPassword) {
                this.isTrue = false;
            }else{
                this.isTrue = true;
            }
        },
        //判断是否设置过支付密码
        isSettig() {
            this.$api.account.isPaymentCode({type:2}).then((res)=>{
                if (res.code == 200) {
                    if (res.data.length==0) {
                        this.isSet = false;
                    }else{
                        this.isSet = true;
                    }
                }
            })
        }
    }
}
</script>
<style scoped>
.password_w,
.pay_w{
    padding: 2% 3% 0;
}
.password_w .van-cell,
.pay_w .van-cell{
    font-size: 1rem;
    padding: 18px 16px 10px;
}
.password_w .van-cell::after,
.pay_w .van-cell::after{
    border-bottom-color: #bbb;
}
.passwordset_w .sumbit_btn{
    margin: 30% 16px 2%;
}
.passwordset_w .sumbit_btn button{
    font-size: 1.2rem;
}
.pay_wt{
    padding: 18px 16px 10px;
    position: relative;
}
.pay_wt::after{
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #bbb;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}
.pay_wt>span{
    display: inline-block;
    vertical-align: middle;
    color: #313131;
    font-weight: 700;
    font-size: .9375rem;
}
.pay_wt .van-password-input{
    width: 70%;
    display: inline-block;
    vertical-align: middle;
}
.pay_wt .van-password-input__security{
    height: 2.0625rem;
}
.pay_wt .van-password-input__security li{
    background-color: #d6d6d6;
    border-radius: 3px;
}
.error{
    font-size: .5rem;
    color: #f00;
    margin-top: 0.5625rem;
}
</style>
<style>
.passwordset_w .van-tabs__wrap{
    border-bottom: 1px solid #ddd;
}
.passwordset_w .van-tabs__wrap .van-tabs__line{
    background-color: #227eeb;
}
.passwordset_w .van-tabs__wrap .van-tab--active{
    font-weight: 700;
}
.passwordset_w .yzmcode_w button{
    top: -4px;
}
.passwordset_w .van-field__label{
    color: #313131;
    width: 5em;
    font-weight: 700;
}
</style>