<template>
    <div class="yzmcode_w">
        <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" :disabled="disabled" @click="getYzmCode">{{codeText}}</van-button>
    </div>
</template>
<script>
export default {
    name: 'GetYzmCode',
    props: {
        username: {
            type: String,
            default: ''
        },
        kind:{
            type: String,
            default:'0'
        }
    },
    data() {
        return {
            disabled: false,
            codeText: '获取'
        }
    },
    methods: {
        //验证码倒计时
        doLoop(seconds) {
            var timer;
            timer = setTimeout(() => {
                this.doLoop(seconds);
            }, 1000);
            if (seconds == 0) {
                this.codeText = '获取';
                this.disabled = false;
                clearTimeout(timer);
            }else{
                this.codeText = seconds + 's';
                this.disabled = true;
                seconds --;
            }
        },
        //获取验证码
        getYzmCode() {
            this.doLoop(60);
            console.log(this.kind)
            if(this.kind=='2'){
                this.$api.register.getCode2(
                    {
                        username: this.username
                    }
                ).then(res => {
                    if (res.code == 200) {
                        //this.$emit('getyzmCode', res.data);
                    }else{
                        //this.$emit('getyzmCode', false);
                        // this.$notify({
                        //     type: 'danger',
                        //     message: res.error,
                        //     duration: 1000
                        // });
                    }
                }).catch(err => {
                    console.log(err);
                });
            }else{
                this.$api.register.getCode(
                    {
                        username: this.username
                    }
                ).then(res => {
                    if (res.code == 200) {
                        //this.$emit('getyzmCode', res.data);
                    }else{
                        //this.$emit('getyzmCode', false);
                        // this.$notify({
                        //     type: 'danger',
                        //     message: res.error,
                        //     duration: 1000
                        // });
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
            
            
        },
    }
}
</script>
<style scoped>
.yzmcode_w button{
    padding: 0 22px;
    font-size: .9rem;
    position: absolute;
    right: 0;
    top: 5px;
}
</style>